import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ImageProps } from 'next/image';
import React from 'react';
import Placeholder from '../../assets/images/placeholder.png';

export const MyImage = (props: ImageProps & { visibleByDefault?: boolean }) => {
  const { visibleByDefault, ...imgProps } = props;
  return (
    <LazyLoadImage
      {...props}
      alt={imgProps.alt}
      height={imgProps.height}
      src={imgProps.src}
      width={imgProps.width}
      placeholderSrc={Placeholder.src}
    />
    // <img
    //   {...props}
    //   style={{ width: props.width, height: props.height }}
    //   src={url as string}
    //   alt={props.alt}
    // />
  );
};

export default MyImage;
