import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FilterContext } from '../../utils/context/filterContext';
import LeftIcon from '../../assets/icons/left_icon.svg';
import { useRouter } from 'next/router';
import { useConfig } from '../../config/queries';
import { FilterFooter } from './FilterModalFooter';
import { UpdateFilter } from './UpdateFilter';
import { FilterList } from './FiltersList';
import { get } from 'lodash';
import { OptionTx } from '../../types/Option';
import styles from './filter.module.css';

export const FilterModalContent = () => {
  const router = useRouter();
  const { setFilters, filters, entity, setIsOpenFilters } =
    useContext(FilterContext);
  const {
    data: { casinoOptionsMap, bonusOptionsMap },
  } = useConfig();
  const options = entity === 'casinos' ? casinoOptionsMap : bonusOptionsMap;
  const [selectedOptionId, changeSelectedId] = useState<number>();
  const selectedOptionTx: OptionTx = get(options, [
    selectedOptionId,
    router.locale,
  ]);

  const handleClearAllFilters = () => {
    if (selectedOptionId) {
      const { [selectedOptionId]: ommit, ...updatedFilters } = filters;
      setFilters(updatedFilters);
    } else {
      setFilters({});
    }
  };

  const handleApplyFilters = () => {
    router.push(router);
    setIsOpenFilters(false);
  };

  const selectFilter = (optionId: number) => () => {
    changeSelectedId(optionId);
  };

  const closeSelectedFilter = () => {
    changeSelectedId(null);
  };

  return (
    <div className={styles.filterModalWrapper}>
      <h6>
        {selectedOptionId ? (
          <div
            className={styles.selectedFilterBar}
            onClick={closeSelectedFilter}
          >
            <LeftIcon /> {selectedOptionTx.name}
          </div>
        ) : (
          <FormattedMessage defaultMessage="Filters" />
        )}
      </h6>

      {selectedOptionId ? (
        <UpdateFilter optionTx={selectedOptionTx} optionId={selectedOptionId} />
      ) : (
        <div className={styles.filterList}>
          <FilterList options={options} selectFilter={selectFilter} />
        </div>
      )}
      <FilterFooter
        handleClearAllFilters={handleClearAllFilters}
        optionTx={selectedOptionTx}
        handleApplyFilters={handleApplyFilters}
      />
    </div>
  );
};
