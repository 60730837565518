import { useEffect } from 'react';
import Head from 'next/head';
import TagManager from 'react-gtm-module';

export const GoogleFields = () => {
    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-58KJXKKN' });
    }, []);
    return (
        <>
            <Head>
                <meta name="google-site-verification" content="ZEIXstEloJzBc0lUPkdcmCc5czTBgzgAq1tDRjVB--c" />
            </Head>
        </>
    );
};
