import { FormControlLabel } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { OptionTx } from '../../types/Option';
import { FilterContext } from '../../utils/context/filterContext';
import SearchIcon from '../../assets/icons/search_icon.svg';
import { InputWithIcon } from '../InputWithIcon';
import styles from './filter.module.css';
import { MyImage } from '../MyImage';
import { CustomCheckbox } from '../Checkbox';
import { useRouter } from 'next/router';

export const UpdateFilter = ({
  optionTx,
  optionId,
}: {
  optionTx: OptionTx;
  optionId: number;
}) => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const [search, changeSearch] = useState('');
  const { filters, setFilters } = useContext(FilterContext);
  const selectedValues = filters[optionId] || [];
  const optionValues = filters[optionId];
  const isAllSelected = optionTx.values.length === optionValues?.length;

  const handleAddOptionValue = (optionValueId: number | 'all') => () => {
    if (optionValueId === 'all') {
      setFilters({
        ...filters,
        [optionId]: isAllSelected ? [] : optionTx.values.map((o) => o.casinoOptionValueId),
      });
      return;
    }
    if (!optionValues) {
      setFilters({ ...filters, [optionId]: [optionValueId] });
    } else {
      const isValueAlreadySelected = optionValues.includes(optionValueId);
      if (isValueAlreadySelected) {
        setFilters({
          ...filters,
          [optionId]: optionValues.filter((currId) => currId !== optionValueId),
        });
      } else {
        setFilters({
          ...filters,
          [optionId]: [...optionValues, optionValueId],
        });
      }
    }
  };

  const handleChangeSearch = (value: string) => {
    changeSearch(value);
  };

  return (
    <div className={styles.optionsList}>
      <InputWithIcon
        label={formatMessage({
          defaultMessage: 'Search',
        })}
        Icon={SearchIcon}
        onChange={handleChangeSearch}
      />
      <div className={styles.listItems}>
        {!!optionTx.values.length && (
          <FormControlLabel
            label={<FormattedMessage defaultMessage="All" />}
            control={
              <CustomCheckbox
                defaultChecked={isAllSelected}
                checked={optionTx.values.length === selectedValues.length}
                onChange={handleAddOptionValue('all')}
              />
            }
          />
        )}
        {optionTx.values.map((option) => {
          if (
            !option.name.toLocaleLowerCase().includes(search.toLowerCase()) ||
            option.languageCode !== router.locale
          ) {
            return null;
          }
          return (
            <FormControlLabel
              key={option.casinoOptionValueId}
              label={
                <div className={styles.optionWrapper}>
                  {option.img && (
                    <div>
                      <MyImage
                        width={24}
                        src={option.img}
                        layout="fill"
                        object-fit="contain"
                        alt={option.name}
                      />
                    </div>
                  )}
                  <span> {option.name}</span>
                </div>
              }
              checked={selectedValues.includes(option.casinoOptionValueId)}
              control={
                <CustomCheckbox
                  defaultChecked={selectedValues.includes(option.casinoOptionValueId)}
                  onChange={handleAddOptionValue(option.casinoOptionValueId)}
                />
              }
            />
          );
        })}
      </div>
    </div>
  );
};
