import {
  Config,
  NormalizedConfig,
  NormalizedOption,
  NormalizedOptionValue,
} from '../../types/Config';
import { Option } from '../../types/Option';

interface NormalizedConfigOption {
  optionValuesMap: NormalizedOptionValue;
  optionsMap: NormalizedOption;
}

const normalizeOption = (options: Option[]): NormalizedConfigOption =>
  options.reduce(
    (acc, opt) => {
      let values = {};
      const translations = opt.translations.reduce((acc, translation) => {
        const translationValues = translation.values.reduce(
          (acc, optValue) => ({
            ...acc,
            [optValue.casinoOptionValueId]: optValue,
          }),
          {},
        );
        values = { ...values, ...translationValues };
        return {
          ...acc,
          [translation.languageCode]: translation,
        };
      }, {});
      return {
        optionsMap: {
          ...acc.optionsMap,
          [opt.id]: translations,
        },
        optionValuesMap: {
          ...acc.optionValuesMap,
          ...values,
        },
      };
    },
    { optionsMap: {}, optionValuesMap: {} },
  );

export const normalizeConfig = (config: Config): Config & NormalizedConfig => {
  const {
    optionsMap: casinoOptionsMap,
    optionValuesMap: casinoOptionValuesMap,
  } = normalizeOption(config.prefilledCasinoOptions);
  const { optionsMap: bonusOptionsMap, optionValuesMap: bonusOptionValuesMap } =
    normalizeOption(config.prefilledBonusOptions);

  return {
    ...config,
    optionValuesMap: {
      ...casinoOptionValuesMap,
      ...bonusOptionValuesMap,
    },
    casinoOptionsMap,
    bonusOptionsMap,
  };
};
