import Link from 'next/link';
import clsx from 'classnames';
import { useMemo, useState } from 'react';
import { generateMenu, langs } from './const';
import { IMenuItem } from './type';
import BottomIcon from '../../assets/icons/bottom_icon.svg';
import TopIcon from '../../assets/icons/top_icon.svg';
import styles from './menu.module.css';
import { useRouter } from 'next/router';
import { useConfig } from '../../config/queries';
import { countriesInfo } from '../../config/countriesFlag';
import classNames from 'classnames';

export const BurgerMenuItem = ({
  item,
  nested,
}: {
  item: IMenuItem;
  nested?: boolean;
}) => {
  const router = useRouter();
  const [isOpen, changeOpenState] = useState(false);
  let titleComponent;
  if (item.subTitle) {
    titleComponent = (
      <div
        className={clsx(styles.subTitle, {
          [styles.red]: item.color === 'red',
          [styles.capitalize]: nested,
        })}
      >
        {item.title}
      </div>
    );
  } else if (item.href) {
    titleComponent = (
      <div className={styles.link}>
        <Link href={item.href}>
          <a className={nested && styles.nestedLink}>{item.title}</a>
        </Link>
      </div>
    );
  }

  const handleClickIcon = () => {
    changeOpenState(!isOpen);
  };

  return (
    <li>
      <div
        className={classNames(styles.titleWrapper, {
          [styles.activeLink]: nested && item.href === router.asPath,
        })}
        onClick={handleClickIcon}
      >
        {titleComponent || item.title}{' '}
        {item.items && <>{isOpen ? <TopIcon /> : <BottomIcon />}</>}
      </div>
      {nested && <div className={styles.deliniator}></div>}
      {item.items && isOpen && (
        <ul className={styles.list}>
          {item.items.map((item) => (
            <BurgerMenuItem nested item={item} key={item.title + item.href} />
          ))}
        </ul>
      )}
    </li>
  );
};

export const BurgerMenu = () => {
  const { locale = 'en', pathname, asPath, ...rest } = useRouter();
  const { data, isLoading } = useConfig();
  const menuList = useMemo(
    () => (isLoading ? [] : generateMenu({ config: data, locale })),
    [locale, data, isLoading],
  );

  return (
    <div className={styles.menu}>
      <ul className={styles.list}>
        {menuList.map((item) => (
          <BurgerMenuItem item={item} key={item.title + item.href} />
        ))}
      </ul>
      <div className={styles.langWrapper}>
        {data.languages?.map((lang) => (
          <div key={lang} className={clsx(locale === lang && styles.active)}>
            <Link href={asPath} locale={lang}>
              <a>
                {lang.toUpperCase()} {countriesInfo[lang.toUpperCase()]?.emoji}
              </a>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
