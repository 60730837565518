import { Button } from '../Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './filter.module.css';
import { OptionTx } from '../../types/Option';

export const FilterFooter = ({
  handleClearAllFilters,
  optionTx,
  handleApplyFilters,
}: {
  handleClearAllFilters: () => void;
  optionTx: OptionTx;
  handleApplyFilters: () => void;
}) => (
  <div className={styles.bottomWrapper}>
    <div onClick={handleClearAllFilters} className={styles.clearBtn}>
      <FormattedMessage
        defaultMessage="Clear All {filterTitle}"
        values={{ filterTitle: optionTx?.name || '' }}
      />
    </div>
    <Button
      variant="primary"
      className={styles.submitBtn}
      onClick={handleApplyFilters}
    >
      {optionTx ? (
        <FormattedMessage defaultMessage="Save" />
      ) : (
        <FormattedMessage defaultMessage="Show" />
      )}
    </Button>
  </div>
);
