import React, { useMemo } from 'react';
import { ThemeProvider } from '@emotion/react';
import type { AppProps } from 'next/app';
import { IntlProvider } from 'react-intl';
import { useRouter } from 'next/router';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import Layout from '../components/layout';
import theme from '../config/theme';
import initAuth from '../utils/initAuth';
import { FilterContextProvider } from '../utils/context/filterContext';
import English from '../content/compiled-locales/en.json';
import Portuguese from '../content/compiled-locales/pt.json';
import '../styles/global.css';
import { useConfig } from '../config/queries';

initAuth();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  },
});

const PagesRender = ({ Component, pageProps }: AppProps) => {
  useConfig();
  return (
    <Layout>
      <Component {...pageProps} />
    </Layout>
  );
};

function MyApp({ Component, pageProps }: any) {
  const [queryClient] = React.useState(() => new QueryClient());
  const { locale } = useRouter();
  const messages = useMemo(() => {
    switch (locale) {
      case 'pt':
        return Portuguese;
      case 'en':
      default:
        return English;
    }
  }, [locale]);

  return (
    <>
      <IntlProvider
        locale={locale || 'en'}
        messages={messages}
        onError={() => null}
      >
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <FilterContextProvider>
                {/* @ts-ignore */}
                <PagesRender Component={Component} pageProps={pageProps} />
              </FilterContextProvider>
            </Hydrate>
          </QueryClientProvider>
        </ThemeProvider>
      </IntlProvider>
    </>
  );
}

export default MyApp;
