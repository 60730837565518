import styles from './layout.module.css';
import clsx from 'classnames';
import React from 'react';
import { Header } from '../header';
import { Footer } from '../footer';
import { GoogleFields } from '../GoogleFields';

const Layout = ({ children }: any) => {
  return (
      <>
            <GoogleFields />
            <div className={clsx(styles.container)}>
              <Header />
              <main className={styles.mainCotainer}>{children}</main>
              <Footer />
            </div>
      </>
  );
};

export default Layout;
