import { omit } from 'lodash';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

export interface Filter {
  // key is option key, and value is array of value ids
  [key: number]: number[];
}

export const filterDefaultState = {
  entity: '',
  isOpenFilters: false,
  filters: {} as Filter,
  setFilters: (d: Filter) => {},
  setIsOpenFilters: (isOpen: boolean) => {},
  revertFilterChanges: () => {},
  setTemporaryFilterState: () => {},
};

export const FilterContext = React.createContext(filterDefaultState);

const initState = {
  enyity: '',
  isOpenFilters: false,
  filters: {},
};

export const FilterContextProvider: React.FC<any> = ({ children }) => {
  const router = useRouter();
  const [entity, setEntity] = useState('');
  const [state, setState] = useState(initState);
  const [temporaryFilters, setTemporaryFilters] = useState(initState.filters);

  const setIsOpenFilters = (isOpenFilters: boolean) => {
    setState({ ...state, isOpenFilters });
  };

  useEffect(() => {
    const routerFilters = omit(router.query, ['categoryId', 'tab', 'id']);
    const normalizedFilters = Object.entries(routerFilters).reduce(
      (acc, [filterid, filterValues]) => {
        const value =
          filterid === 'search' ? filterValues : Number(filterValues);
        return {
          ...acc,
          [filterid]: Array.isArray(filterValues)
            ? filterValues.map(Number)
            : [value],
        };
      },
      {},
    );
    setState({ ...state, filters: normalizedFilters });
  }, [router.query]);

  useEffect(() => {
    const currentEntity = router.pathname.includes('bonuses')
      ? 'bonuses'
      : 'casinos';
    if (currentEntity !== entity) {
      setEntity(currentEntity);
    }
    setState((s) => ({ ...s, isOpenFilters: false }));
  }, [router.pathname]);

  const setFilters = (filters: Filter) => {
    setState({ ...state, filters });
    router.query = Object.entries(filters).reduce(
      (acc, [id, valueIds]) => ({ ...acc, [id]: valueIds }),
      {},
    );
    router.push(router);
  };

  const revertFilterChanges = () => {
    setFilters(temporaryFilters);
  };

  const setTemporaryFilterState = () => {
    setTemporaryFilters(state.filters);
  };

  return (
    <FilterContext.Provider
      value={{
        ...state,
        entity,
        setFilters,
        setIsOpenFilters,
        revertFilterChanges,
        setTemporaryFilterState,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
