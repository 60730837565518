import MuiButton from '@mui/material/Button';
import clsx from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './button.module.css';
import { IButton } from './types';

export const Button = ({
  disabled,
  variant,
  fullWidth,
  children,
  className,
  onClick,
  isLoading,
}: IButton) => (
  <MuiButton
    onClick={onClick}
    disabled={disabled || isLoading}
    className={clsx(
      styles.btn,
      {
        [styles.primary]: variant === 'primary',
        [styles.secondary]: variant === 'secondary',
        [styles.fullWidth]: !!fullWidth,
      },
      className,
    )}
  >
    {isLoading ? <FormattedMessage defaultMessage="Loading..." /> : children}
  </MuiButton>
);
