import { Checkbox } from '@mui/material';
import { withStyles } from '@mui/styles';

const checkBoxStyles = (theme) => ({
  root: {
    '&$checked': {
      color: '#E70C0E',
    },
  },
  checked: {},
});

export const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
