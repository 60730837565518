import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './input.module.css';
import CrossIcon from '../../assets/icons/cross_icon.svg';
interface InputWithIcon {
  label: string;
  Icon: typeof React.Component;
  onEnterKey?: (v: string) => void;
  onChange?: (v: string) => void;
  onIconClick?: () => void;
  _value?: string;
  minimize?: boolean;
}

export const InputWithIcon = ({
  label,
  Icon,
  minimize,
  onChange,
  onEnterKey,
  onIconClick,
  _value = '',
}: InputWithIcon) => {
  const [value, setValue] = useState(_value);
  // const [activated, changeActivated] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange && onChange(e.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && onEnterKey) {
      // changeActivated(false);
      onEnterKey(value);
    }
  };

  const handleIconClick = () => {
    // if (minimize) {
    //   changeActivated(false);
    // }
    onIconClick && onIconClick();
  };

  const handleClearClick = () => {
    setValue('');
    onChange && onChange('');
  };

  // useEffect(() => {
  //   changeActivated(false);
  // }, [changeActivated, minimize]);

  return (
    <div
      className={classNames(styles.inputWrapper, {
        [styles.minimized]: minimize,
      })}
    >
      <input
        id="outlined-start-adornment"
        className={styles.input}
        // type="search"
        onChange={handleChange}
        placeholder={label}
        value={value}
        onKeyDown={handleKeyDown}
        disabled={minimize}
      />
      <div className={styles.iconWrapper} onClick={handleIconClick}>
        <span>
          <Icon style={{ color: 'red' }} />
        </span>
      </div>
      {!!value.length && (
        <div className={styles.clearIcon} onClick={handleClearClick}>
          <span>
            <CrossIcon />
          </span>
        </div>
      )}
    </div>
  );
};
