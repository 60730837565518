import { FormattedMessage } from 'react-intl';
import { Config } from '../../types/Config';
import { IMenuItem } from './type';

export const generateMenu = ({
  config,
  locale,
}: {
  config: Config;
  locale: string;
}): IMenuItem[] => {
  return [
    {
      title: <FormattedMessage defaultMessage="Casinos" />,
      subTitle: true,
      items: config.prefilledCasinoCategories.map((category) => {
        // @ts-ignore
        const translationValue = category.translations.find(
          (el) => el.languageCode === locale,
        );
        return {
          title: translationValue.name,
          href: `/casinos?categoryId=${category.id}`,
        };
      }),
      href: '/casinos',
    },
    {
      title: <FormattedMessage defaultMessage="Bonuses" />,
      subTitle: true,
      items: config.prefilledBonusCategories.map((category) => {
        // @ts-ignore
        const translationValue = category.translations.find(
          (el) => el.languageCode === locale,
        );
        return {
          title: translationValue.name,
          href: `/bonuses?categoryId=${category.id}`,
        };
      }),

      href: '/bonuses',
    },
    {
      title: <FormattedMessage defaultMessage="Reviews" />,
      subTitle: false,
      href: '/reviews',
    },
    {
      subTitle: false,
      title: <FormattedMessage defaultMessage="About" />,
      href: '/about',
    },
    {
      subTitle: false,
      title: <FormattedMessage defaultMessage="Contacts" />,
      href: '/contact-us',
    },
    {
      subTitle: false,
      title: <FormattedMessage defaultMessage="Privacy Policy" />,
      href: '/privacy-policy',
    },
  ];
};

export const langs = [
  { title: 'EN', flag: '🇬🇧', locale: 'en' },
  { title: 'ES', flag: '🇪🇸', locale: 'es' },
];

export const defaultLangLocale = 'en'
