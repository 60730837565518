import { Box, Typography } from '@mui/material';
import clsx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import BonusesIcon from '../../assets/icons/bonuses_icon.svg';
import CasinosIcon from '../../assets/icons/casinos_icon.svg';
import HomeIcon from '../../assets/icons/home_icon.svg';
import ProfileIcon from '../../assets/icons/profile_icon.svg';
import ReviewIcon from '../../assets/icons/reviews_icon.svg';
import { FilterContext } from '../../utils/context/filterContext';
import styles from './footer.module.css';

const mainNavigationList = [
  {
    icon: <HomeIcon />,
    title: <FormattedMessage defaultMessage="Home" />,
    href: '/',
  },
  {
    icon: <CasinosIcon />,
    title: <FormattedMessage defaultMessage="Casinos" />,
    href: '/casinos',
  },
  {
    icon: <BonusesIcon />,
    title: <FormattedMessage defaultMessage="Bonuses" />,
    href: '/bonuses',
  },
  {
    icon: <ReviewIcon />,
    title: <FormattedMessage defaultMessage="Reviews" />,
    href: '/reviews',
  },
  {
    icon: <ProfileIcon />,
    title: <FormattedMessage defaultMessage="Profile" />,
    href: '/profile',
  },
];

export const Footer = () => {
  const router = useRouter();
  const { isOpenFilters } = useContext(FilterContext);
  let hasActive = false;
  const navList = mainNavigationList.map((navItem) => {
    let active = false;
    if (
      (router.pathname === '/' && navItem.href === '/') ||
      (router.pathname.includes(navItem.href) && navItem.href !== '/')
    ) {
      active = true;
      hasActive = true;
    }
    return {
      ...navItem,
      active,
    };
  });

  return (
    <footer
      className={clsx(styles.footer, { [styles.noShadow]: isOpenFilters })}
    >
      {navList.map((navItem) => (
        <Box key={navItem.href} className={styles.navItem}>
          <Link href={navItem.href}>
            <div
              className={clsx(
                styles.navItemWrapper,
                (navItem.active || (!hasActive && navItem.href === '/')) &&
                  styles.activeNavItem,
              )}
            >
              <div className={styles.navIcon}>{navItem.icon}</div>
              <Typography component="div">{navItem.title}</Typography>
            </div>
          </Link>
        </Box>
      ))}
    </footer>
  );
};
