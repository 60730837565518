import React, { useContext } from 'react';
import styles from './filter.module.css';
import RightIcon from '../../assets/icons/right_icon.svg';
import { useRouter } from 'next/router';
import { get, pick } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { FilterContext } from '../../utils/context/filterContext';
import { NormalizedOption } from '../../types/Config';
import { useConfig } from '../../config/queries';
import { OptionTx } from '../../types/Option';

const FilterOption = ({
  option,
  optionId,
  selectFilter,
}: {
  option: Record<string, OptionTx>;
  optionId: number | string;
  selectFilter: (option: number | string) => () => void;
}) => {
  const { locale = 'en' } = useRouter();
  const {
    data: { optionValuesMap },
  } = useConfig();
  const { filters } = useContext(FilterContext);
  const optionTx: OptionTx = get(option, [locale], option.en);
  const selectedValueIds: (string | number)[] = get(filters, optionId);
  const optionsValues = pick(optionValuesMap, selectedValueIds);
  const valuesName = Object.values(optionsValues).map((value) =>
    get(value, 'name', ''),
  );

  return (
    <div className={styles.filterWrapper} onClick={selectFilter(optionId)}>
      <div className={styles.filterInfo}>
        <span className={styles.filterTitle}>{optionTx.name}</span>
        <span className={styles.filterValue}>
          {valuesName ? (
            valuesName.join(', ')
          ) : (
            <FormattedMessage defaultMessage="All" />
          )}
        </span>
      </div>
      <RightIcon />
    </div>
  );
};

export const FilterList = ({
  options,
  selectFilter,
}: {
  options: NormalizedOption;
  selectFilter: (option: number | string) => () => void;
}) => (
  <>
    {Object.entries(options).map(([optionId, option]) => (
      <FilterOption
        key={optionId}
        optionId={optionId}
        option={option}
        selectFilter={selectFilter}
      />
    ))}
  </>
);
