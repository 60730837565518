import { useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import styles from './header.module.css';
import MenuIcon from '../../assets/icons/menu_icon.svg';
import CrossIcon from '../../assets/icons/cross_icon.svg';
import { BurgerMenu } from '../Menu';
import { FilterContext } from '../../utils/context/filterContext';
import { FilterModalContent } from '../Filter/FilterModal';

export const Header = () => {
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const { isOpenFilters, revertFilterChanges, setIsOpenFilters } =
    useContext(FilterContext);

  const handleDrawerToggle = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const handleCloseFilter = () => {
    revertFilterChanges();
    setIsOpenFilters(!isOpenFilters);
  };

  useEffect(() => {
    setMenuOpen(false);
  }, [router.asPath]);

  return (
    <header>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar component="nav" className={styles.customizeAppBar}>
          <Toolbar sx={{ minHeight: 48 }} className={styles.customizeToolbar}>
            <div className={styles.logoWraopper}>
              {!(menuOpen || isOpenFilters) && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 1 }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Link href="/">
                <a>
                  <Typography
                    variant="h6"
                    component="div"
                    className={styles.logoText}
                  >
                    stakeschips
                  </Typography>
                </a>
              </Link>
            </div>
            {!!menuOpen && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: -2 }}
              >
                <CrossIcon />
              </IconButton>
            )}
            {!!isOpenFilters && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleCloseFilter}
                sx={{ mr: -2 }}
              >
                <CrossIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
        {menuOpen && (
          <div className={styles.drawer}>
            <BurgerMenu />
          </div>
        )}
        {isOpenFilters && (
          <div className={styles.drawer}>
            <FilterModalContent />
          </div>
        )}
      </Box>
    </header>
  );
};
