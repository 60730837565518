import { useMutation, UseMutationOptions, useQuery } from 'react-query';
import { Config } from '../../types/Config';
import { normalizeConfig } from '../../utils/normlize/config';
import { JockerApi } from '../api';

interface ContactPost {
  sender: string;
  from: string;
  to: string;
  replyTo: string;
  text: string;
  subject: string;
}

export const fetchConfig = async () => {
  const { data } = await JockerApi.get<Config>('metadata');
  return normalizeConfig(data);
};

export const prefetchConfig = async (queryClient) => {
  await queryClient.prefetchQuery({
    queryKey: ['load-config'],
    queryFn: fetchConfig,
  });
};

export const useConfig = () =>
  useQuery(['load-config'], fetchConfig, {
    staleTime: Infinity,
  });

const postReview = async ({ ...form }: ContactPost) => {
  try {
    const { data } = await JockerApi.post<any>(`helpers/sendMail`, form, {
      timeout: 1 * 60 * 1000,
    });
    return { ...data } as any;
  } catch (e) {
    console.log(e);
  }
};

export const usePostContact = (
  options?: UseMutationOptions<any, Error, ContactPost>,
) => useMutation<any, Error, ContactPost>(postReview, options);
